.customer-bill-conatiner {
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 1rem;
    margin-top: 0;
  }
}

.bill {
  width: 80%;
  max-width: 330px;
  min-height: 380px;
  height: auto;
  border-radius: 50px;
  box-shadow: inset -4px -8px 16px rgba(0, 0, 0, 0.05),
    inset 4px 5px 16px rgba(0, 0, 0, 0.05);
  padding: 1rem 2rem;
}

.icon-styles {
  height: fit-content;
  width: fit-content;
  margin-inline-start: -20px;
}

.horizontal-line {
  margin-top: 3rem;
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(233, 233, 241, 1);
}