.main-container {
  padding: 0;
}

.screen-content {
  margin: 16px;
}

.increment-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 25%;
  margin-right: 16px;

  button {
    width: 32px;
    height: 32px;
    color: #757575;
    border-radius: 7px;
  }
}

.quantity-display {
  font-weight: 700;
}

.screen-footer {
  display: flex;
  position: sticky;
  bottom: 0;
  box-sizing: border-box;
  height: 84px;
  z-index: 11;


  background: #ffffff;
  /* background */

  border: 1px solid #ebebeb;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
}

.submit-btn {
  width: 56%;
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;

  .text {
    box-sizing: border-box;

    width: 163px;
    height: 48px;

    border: 1px dashed #e81111;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Cairo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    /* or 100% */

    text-align: right;
    text-transform: capitalize;

    color: #e81111;
  }

  .quantity-display {
    background-color: #f2f2f7;
    border-radius: 7px;
    border: none;
    width: 80%;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    width: 30px !important;
    height: 29px;
  }
}

.price {
  border-radius: 20px;
  display: flex;
  align-items: center;

  p {
    padding-right: 17px;
    padding-left: 10px;
    margin-top: 0px;
    margin-block-end: 0px;
    color: #198646;
  }

  del {
    color: #757575;
    margin-top: 4px;
  }

  h3 {
    margin-top: 7px;
  }

  height: 28px;

  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 16px;
  /* or 53% */

  text-align: right;
  text-transform: capitalize;

  /* success */
  color: #000000;
  background: white;

  flex-direction: column;
  justify-content: space-between;
}

.instead_of {
  display: flex;
}

.centre {
  text-align: right;
  font-size: 20px;
}

.variant {
  .price-container {
    margin-right: 0;

    .text {
      display: none;
    }

    .price {
      flex-direction: row;

      p {
        padding-right: 0;
        font-size: 22px;
      }

      del {
        margin-top: 0px;
      }
    }

    .instead_of_text {
      display: none;
    }

    .instead_of {
      margin-bottom: 1px;
    }
  }
}

.instead_of_text {
  margin-left: 12px;
}

.deliviry {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: right;
  text-transform: capitalize;

  /* success */

  color: #3dcc79;
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 48px;
  left: 0px;
  top: 44px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;
  text-transform: capitalize;

  color: #f7c215;

  /* black */

  background: linear-gradient(0deg, #000000, #000000),
    linear-gradient(0deg, #fb1b1c, #fb1b1c),
    linear-gradient(0deg, #fb1b1c, #fb1b1c),
    linear-gradient(0deg, #c40001, #c40001), #c40001;
}

.header-container {
  display: none;
}

.product-description {
  img {
    border-radius: 5px;
  }
}

html {
  scroll-behavior: smooth;
}

/* Style the image size and spacing */

/* Media query for mobile devices */
@media (max-width: 768px) {
  .spacerElement {
    height: 30px;
  }
}

.loading--overlay {
  height: 100%;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(88, 90, 92, 0.4);
  border-radius: 0 8px 8px 8px;
  height: 100%;
}

.loading--overlay.visible {
  display: flex;
}
.waiting-message{
  color: #ffffff;
  font-weight: 700;
}
.in-active {
  color: rgba(119, 119, 119, 1);
}
.in-active-image{
  justify-items: center;
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 20vh 0vh 0vh 0vh;
}

.empty-div{
  justify-items: center;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 1vh;
}
