.centre {
  text-align: center;
}
.resource-container {
  width: 100%;
}

.main-resource {
  max-width: 100%;
  margin-bottom: 0.5rem;
  max-height: 400px;
}
.small-resource {
  border-radius: 15px;
  max-width: 25%;
}
.resources-grid {
  display: inline-flex;
  justify-content: space-between;
  height: 10vh;
  grid-gap: 8px;
  width: 100%;
}
.empty-div {
  width: 25%;
}
.m-auto {
  margin: auto;
  max-height: inherit;
  width: fit-content;
}
.main-img {
  border-radius: 35px;
  object-fit: contain;
  max-width: 100%;
  max-height: inherit;
}

.product-description {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
