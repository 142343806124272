.in-active {
    color: rgba(119, 119, 119, 1);
  }
  .in-active-image{
    justify-items: center;
      width: 100%;
      background-color: white;
      text-align: center;
      padding: 20vh 0vh 0vh 0vh;
  }
  
  .empty-div{
    justify-items: center;
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 1vh;
  }
  