.main-div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    
}

.img-div{
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: center;
}