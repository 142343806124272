.modal-container {
  max-width: 65vw;
  max-height: 600px;
}
.img-style {
  max-width: inherit;
  max-height: inherit;
}
.MuiBox-root:focus {
  outline: none;
}

.close-btn {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: none;
  position: fixed;
  top: 0;
  right: 0;
  background: transparent;
}
