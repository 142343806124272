.failure-container{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;

    .failure-message{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 95%;
        font-weight: 700;
        color:#FB1B1C;
    }
    .close-btn--style{
        width: 100%;
        margin-right: 2px;
        margin-left: 2px;
        button{
            background-color: #24894E;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 14px;
            // line-height: 16.8px;
            padding: 15px;
            border: none;
            border-radius: 10px;
        }
    }
}