/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */
* {
  direction: rtl;
}

body {
  margin: 0;
  font-family: "Tajwal";
  font-weight: 400;
}
button {
  font-family: "Tajwal";
}
.submit-btn {
  width: 100%;
  max-width: 330px;
  border: none;
  border-radius: 30px;
  color: white;
  height: 48px;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
label {
  font-weight: 500;
  font-size: 14px;
  color: #757575;
}
.success {
  color: rgba(61, 204, 121, 1);
}
h2 {
  font-size: larger;
  font-weight: 700;
}
h3 {
  font-size: medium;
  font-weight: 700;
}
h4 {
  font-size: medium;
  font-weight: 500;
}
.grey {
  color: #757575;
}
.blue {
  color: rgba(1, 102, 192, 1);
}
.success-bg {
  background-color: #1c8749;
}
.grey-bg {
  background-color: #777777;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: Tajwal;
  src: local("Tajwal"),
    url("./assets/fonts/Tajawal-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2)
    format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
