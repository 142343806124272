.increment-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

//   button {
//     font-size: larger;
//   }

  .quantity-picker {
    display: flex;
    align-content: center;
    align-items: center;
    border: none;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    color: #757575;
  }

  .quantity-display {
    background-color: #f2f2f7;
    border-radius: 7px;
    border: none;
    width: 80%;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    width: 30px !important;
    height: 29px;
  }
  //   button {
  //     width: 32px;
  //     background: white;
  //     color: black;
  //     border: 1px solid #757575;
  //     border-radius: 10px;
  //     text-align: center;
  //     cursor: pointer;
  //     height: 32px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
}

.price {
  background: rgba(1, 102, 192, 0.08);
  border-radius: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  align-content: center;
  color: #0166c0;

  p {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 20px;
  }
}

.instead_of {
  display: none;
}
