.error {
  .border-style {
    border: 1px solid #fb1b1c !important;
    border-radius: 4px;
  }
}
.errormsg {
  color: #fb1b1c;
  display: block;
}
.errormsg-container {
  height: 8px;
}
.none {
  display: none;
}
.border-style {
  border: none;
}

.dropdown-style {
  background-color: rgba(0, 0, 0, 0.06);
}
.mb {
  margin-bottom: 0.75rem !important;
}
.center {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.btn-content {
  display: grid;
  width: 100%;
  grid-template-columns: 10% 90%;
}
ins {
  display: flex;
  justify-content: center;
}

.radio-container {
  width: 100%;
}
.radio-container img {
  width: 60px;
  height: 60px;
  object-fit: contain;

  // width: 90px;
  // height: 90px;
  // object-fit: contain;
}

.flag-error-input {
  border-color: red;
}

.cash-on-delivery {
  display: flex;
  flex-direction: row;
}

label {
  width: 100%;
}
.MuiFormControlLabel-label {
  width: 100%;
}
.outer-paytabs--container {
  width: 100%;
}

.paytabs-lock--support {
  img {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
}

.paytabs-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .available-paytabs--support {
    justify-self: start;
    display: flex;
    width: 56%;
  }
  .paytabs-powered-by {
    justify-self: end;
    width: 44%;
    text-align: center;
    line-height: 15px;
    display: flex;
    align-items: center;
    .powered-by--style {
      margin-right: 4px;
    }
  }
}

.another-payment--method {
  align-items: center;
  text-align: center;
  button {
    width: 40%;
    height: 48px;
    border-radius: 30px;
    border: 1px solid #24894e;
    background-color: #fff;
    color: #24894e;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
}
.payment-msg {
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  color: #000;
}

.payment--gateway--style {
  display: flex;
  flex-direction: row;
}

.radio-group--style {
  width: 100%;
  margin-top: 10px;
}

.payment-section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}
.payment-available {
  margin-top: 10px;
}
.payment-style {
  border: solid 1px rgb(102, 102, 102);
  border-radius: 8px;
  margin-bottom: 10px;
  display: "flex";
  align-items: center;

  label {
    margin-left: 0;
    margin-right: 0;
  }
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding-left: 5px;
    padding-right: 9px;
  }
}

.payment-label {
  display: flex;
  flex-direction: row;
  gap: 0px;
}

.installment-msg {
  margin-left: 12px;
  margin-right: 4px;
}
.istallment-img {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  img {
    width: 90%;
    height: 200px;
    object-fit: contain;
  }
}

.installment {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.payment-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.payment-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 150px;
  transition: transform 0.3s ease-in-out;
}

.payment-card:hover {
  transform: scale(1.05);
}

.payment-header {
  background-color: #ececf3;
  color: #5f5f5f;
  padding: 4px;
  text-align: center;
}

.payment-body {
  padding: 12px;
  text-align: center;
}

.payment-amount {
  font-size: 20px;
  color: #333;
}

.payment-count {
  font-size: 14px;
  color: #666;
}

.payment-error--style {
  text-align: center;
}
.payment-error--message {
  color: #fb1b1c;
  font-weight: 500;
  font-size: 16px;
}

.available-payment {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.available-payment--txt {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
}
.available-payment--img {
  width: 62px;
  height: 22px;
  margin-left: 5px;
  margin-right: 5px;
}

.loading--overlay {
  height: 100%;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(88, 90, 92, 0.4);
  border-radius: 0 8px 8px 8px;
  height: 100%;
}

.loading--overlay.visible {
  display: flex;
}

.waiting-message {
  color: #ffffff;
  font-weight: 700;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  .title-style {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    background-color: #d6f3e2;
    border: 1px solid #3dcc79;
    border-radius: 10px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width: 60%;
  }
}

.discount-section {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  .discount-background {
    background: linear-gradient(
      260.39deg,
      #f3e00a 40.2%,
      #ff9800 124.32%,
      rgba(51, 30, 0, 0.2) 208.43%
    );
    width: 330px;
    height: 97px;
    border-radius: 15px;
  }
  .discount-content {
    margin-top: 5px;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    color: #000;
  }
}
