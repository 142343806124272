.header-container {
  width: fit-content;
  text-align: center;
  margin-inline-end: auto;
  padding: 1em;
}
.header-text {
  font-weight: 500;
  font-size: medium;
  color: rgba(119, 119, 119, 1);
}
