.map-container {
  position: relative;
  height: 40vh;
  width: 40vh;
  border-radius: 20px;
}

#map-canvas {
  height: 40vh;
  width: 40vh;
  z-index: 1;
  border-radius: 20px;
}

.select-location {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.select-location-btn {
  position: absolute;
  padding: 8px 11px 8px 11px;
  width: 144px;
  height: 36px;
  background-color: #0166c0;
  color: white;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  top: 85%;
  right: 5%;
}

.none {
  display: none;
}
